
// decrypt helper function
function decryptCharcode(n, start, end, offset) {
    n = n + offset;
    if (offset > 0 && n > end) {
      n = start + (n - end - 1);
    } else if (offset < 0 && n < start) {
      n = end - (start - n - 1);
    }
    return String.fromCharCode(n);
  }
  // decrypt string
  function decryptString(enc, offset) {
    var dec = "";
    var len = enc.length;
    for (var i = 0; i < len; i++) {
      var n = enc.charCodeAt(i);
      if (n >= 0x2b && n <= 0x3a) {
        dec += decryptCharcode(n, 0x2b, 0x3a, offset); // 0-9 . , - + / :
      } else if (n >= 0x40 && n <= 0x5a) {
        dec += decryptCharcode(n, 0x40, 0x5a, offset); // A-Z @
      } else if (n >= 0x61 && n <= 0x7a) {
        dec += decryptCharcode(n, 0x61, 0x7a, offset); // a-z
      } else {
        dec += enc.charAt(i);
      }
    }
    return dec;
  }
  // decrypt spam-protected emails
  function linkTo_UnCryptMailto(s) {
    location.href = decryptString(s, 3);
  }

  // werkliste

  $(document).ready(function() {
    
    //$('.werkliste-ueberschrift .werkliste-themenbezeichnung .werkliste-kuenstlername').css('opacity', 1);
    $('.werkliste-ueberschrift').css('opacity', 1);
    $('.werkliste-themenbezeichnung').css('opacity', 1);
    $('.werkliste-kuenstlername').css('opacity', 1);
   
   var $werkliste = $('.werkliste').isotope({
       itemSelector: '.werk',
       percentPosition: true,
       masonry: {
           // use outer width of grid-sizer for columnWidth
           columnWidth: '.werkliste-grid-sizer'
        },
       transitionDuration: '0'
   });
   
   var myLazyLoad = new LazyLoad({
       threshold: 0,
     callback_load: function (e) {
         
       
           $werkliste.isotope('layout');
           
           $(e).closest('.werk').css('visibility', 'visible');
           $(e).closest('.werk').css('opacity', 1);
           
           $(e).closest('.teaserbox').css('visibility', 'visible');
           $(e).closest('.teaserbox').css('opacity', 1);
       }
   });
   
    
   $('#werkliste-back button').on('click', function() {
       $('.werk-detail').fadeOut(600);
   });
   
   var resizeTimer;
   
   function resizeFunction() {
       if ($('body').hasClass('body-werkliste-detail')) {
           if ($(window).width() >= 576) {
           //if ($(window).width() >= 768) {
               
               //var topbarHeight = $('.container.topbar').height();
               var topbarHeight = $('#topbar').outerHeight(true);
               var navTabsHeight = $('.nav-tabs').height();
               var viewportHeight = $(window).height();
               var bildWrapperHeight = $('.werk-detail-bild-wrapper').outerHeight(true);
               var bildWrapperMargins = $('.werk-detail-bild-wrapper').outerHeight(true) - $('.werk-detail-bild-wrapper').height();
           
               var bildWrapperNewHeight = viewportHeight - topbarHeight - navTabsHeight;
             $('.werk-detail-bild-wrapper').height(bildWrapperNewHeight - bildWrapperMargins);
           }
       }
   };
   
    if (window.matchMedia("screen").matches) {
     
      $(window).resize(function () {
           clearTimeout(resizeTimer);
           resizeTimer = setTimeout(resizeFunction, 100);
       });

       resizeFunction();
   }    
});

